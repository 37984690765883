import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import NewsBanner from '../components/Banners/NewsBanner'
import GridCarousel from '../components/Caroussels/GridCarousel'
import FactsCaroussel from '../components/Caroussels/FactsCaroussel'
import MoreBannerLink from '../components/Banners/MoreBannerLink'
import { footerEn /* footerAr*/ } from '../data/footer'
import { HeaderBanner } from '../data/banner'
import BannerHero from '../components/Banners/BannerHero'
import {BannerData} from "../data/BannerData";
// import ResearchDayBanner from '../components/ResearchDayBanner/ResearchDayBanner';

// import axios from 'axios'
import { Component } from 'react'

import { navigationTop, navigationButtom } from '../params'

const grid = {
  title: 'HBKU Research Today',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis iaculis magna sit amet scelerisque venenatis. Nullam elementum felis vel metus dignissim mollis.',
    items: [
      {
        tag: [
          { classN: '', text: 'Partners' },
          // { classN: 'light', text: 'Press release' },
        ],
        image: 'https://www.hbku.edu.qa/sites/default/files/news/image3_4.jpg',
        title: 'HBKU and LCI-Qatar Support Lean Transformation of Construction Industry',
        description:"Joint MoU advances efforts towards sustainability in Qatar’s built environment",
        url:'https://www.hbku.edu.qa/en/news/HBKU-OTHER-LCISLTCI',
    
      },
      {
        tag: [
          { classN: '', text: 'Excellence' },
          // { classN: 'light', text: 'Press release' },
        ],
        image: 'https://www.hbku.edu.qa/sites/default/files/news/image1_174.jpg',
        title: 'Qatar Environment and Energy Research Institute Achieves ISO Accreditation for Calibration and Testing Laboratories',
        description:"QEERI upholds global standards as national RDI institution tackling Qatar’s water, energy, and environment challenges",
        url:'https://www.hbku.edu.qa/en/news/QEERI-SUST-ISOACTL',
    
      },
      {
        tag: [
          { classN: '', text: 'Innovation' },
          // { classN: 'light', text: 'Press release' },
        ],
        image: 'https://www.hbku.edu.qa/sites/default/files/news/image1_175.jpg',
        title: 'Middle School Seniors Become Technology Creators at Qatar Computing Research Institute’s Creative Space Summer Camp',
        description:"Qatar Computing Research Institute (QCRI), part of Hamad Bin Khalifa University (HBKU), welcomed enthusiastic middle school seniors from across Qatar to its annual Creative Space Summer Camp, which commenced on August 8. ",
        url:'https://www.hbku.edu.qa/en/news/QCRI-PE-MSSBTC',
    
      },
      {
        tag: [
          { classN: '', text: 'Press Releases' },
          // { classN: 'light', text: 'Press release' },
        ],
        image: 'https://www.hbku.edu.qa/sites/default/files/styles/event_detail_482x335/public/news/image1_239.jpg',
        title: 'HBKU Research Day Highlights Impact and Excellence',
        description:"HBKU showcases faculty and student research accomplishments",
        url:'https://www.hbku.edu.qa/en/news/HBKU-ACRS-RDHIAE',
    
      },
      {
        tag: [
          { classN: '', text: 'Excellence' },
          // { classN: 'light', text: 'Press release' },
        ],
        image: 'https://www.hbku.edu.qa/sites/default/files/styles/event_detail_482x335/public/news/image1_11_1.jpg',
        title: 'CSE Awarded Grant to Create a National Risk Management Plan for Qatar',
        description:"QAR 16.1 million QNRF grant will enable HBKU and international partners to strengthen the risk-resilience-sustainability nexus.",
        url:'https://www.hbku.edu.qa/en/news/CSE-ST-AGCNRMPQ',
      },
      
    ],
}

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
]

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      title: 'Home',
      body: '',
      welcomeMessage: null,
      focusAreas: null,
      quickLinks: null,
      items: [],
      eventItems: [],
      DataisLoaded: false,
      DataisEventLoaded: false
    }
  }
  
  componentDidMount() {
    this.newsDataFetch();
    this.eventDataFetch();
  }
  newsDataFetch() {
    window.location.href = '/research/office-vpr'; // Redirect to /new-path
    fetch(
      "https://www.hbku.edu.qa/en/api/ri-news.json?field_news_category_tid=2678&language=en")
      .then((res) => res.json())
      .then((json) => {
          const updatedData = json.map((item) => {
            return {
              // tag: item?.news_category?.map(cat=>({
              //   class: '',
              //   text: cat
              // })),
              image: item?.Image,
              title: item?.title,
              description: item?.summary,
              url:'https://www.hbku.edu.qa/en/node/'+ item?.nid,
            }
          });
          this.setState({
              items: updatedData,
              DataisLoaded: true
          });
      })
  }
  eventDataFetch() {
    fetch(
      "https://www.hbku.edu.qa/en/api/ri-event.json?field_event_category_tid=2684&language=en")
      .then((res) => res.json())
      .then((json) => {
          const updatedEventData = json.map((item) => {
            return {
              day: (item?.eventDay == item?.eventEndDate) ? item?.eventDay : [item?.eventDay, '-', item?.eventEndDate],
              mounth: (item?.eventMon == item?.eventEndMon) ? item?.eventMon : [item?.eventMon, ' - ', item?.eventEndMon],
              name: item?.title,
              description: item?.body,
              tag: item?.eventCategory,
              image: item?.image,
              url: 'https://www.hbku.edu.qa/en/node/'+ item?.nid,
            }
          });
          this.setState({
              eventItems: updatedEventData,
              DataisEventLoaded: true
          });
      })  
  }
  componentDidUpdate() {
    document.title = `${this.state.title} - HBKU`
  }

  // componentDidMount() {
  //   axios
  //     .get('/research/caa14df2-2019-4955-a8fc-dbfd1a4b18f7')
  //     .then(res => {
  //       const attributes = res.data.data.attributes
  //       const relationships = res.data.data.relationships
  //       let state = {
  //         id: res.data.data.id,
  //         title: attributes.title,
  //         body: attributes.body.processed,
  //         welcomeMessage: relationships.field_official_message.links.related.href,
  //         focusAreas: relationships.field_thematic_focus_areas.links.related.href,
  //         quickLinks: relationships.field_quick_links.links.related.href,
  //       }

  //       this.setState(state)
  //     })
  //     .catch(err => console.log(err))
  // }

  render() {
   // const { DataisLoaded, items } = this.state;
    const headerData = {
      title: 'Research at HBKU',
      crumb: crumb,
      navigationButtom: navigationButtom,
      navigationTop: navigationTop,
      applicationLinks: [{ text: 'Apply now', class: 'bordred', link: 'https://hbkuqa.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply' }],
    }

    const factCarousel = {
      title: 'Research Activities',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dolor sapien, sodales vehicula leo et, elementum vulputate sapien. Nulla lacinia vehicula dapibus.',

        items: [
          
          {
            day: '21',
            mounth: 'NOV',
            name: 'Thematic Research Highlights',
            description: 'The Office of the Vice President for Research (OVPR) is pleased to invite you to attend the first of a series of sessions that will showcase HBKU’s on-going thematic research.',
            tag: 'Event',
            image: '/uploads/event-bg-ph.jpg',
            url: 'https://www.hbku.edu.qa/en/academic-events/OVPR-TRH',
          },
          {
            day: "24",
            mounth: 'NOV',
            name: 'Applications of KREX and Orvar Technologies for Autoantibody-based Biomarker Discovery',
            description: "Using conventional methods, it would be necessary to screen every single human protein, splice variant, neoantigen or microbial antigen to discover the total repertoire of autoantigens within a disease. ",        
            tag: 'Webinar',
            image: '/uploads/event-bg-ph.jpg',
            url: 'https://www.hbku.edu.qa/en/academic-events/QBRI-PM-AKREXOTA',
          },
          {
            day: "29 - 01",
            mounth: 'Nov-Dec',
            name: 'The First Advances in Precision Medicine (APM2021) Conference',
            description: "The first Advances in Precision Medicine (APM2021) conference will focus on “Epigenetics and Precision Medicine” to highlight exciting recent developments in the field of epigenetics related to clinical decision making, diagnosis, and treatment.",        
            tag: 'Conference / Precision Medicine',
            image: '/uploads/event-bg-ph.jpg',
            url: 'https://www.hbku.edu.qa/en/APM21',
          },
         {
            day: '29 - 31',
            mounth: 'Mar 2022',
            name: 'The 17th International Conference on Persuasive Technology 2022',
            description: "Persuasive Technology (PT) is a vibrant interdisciplinary research field focusing on the design, development, and evaluation of interactive technologies. PT aims at changing people’s attitudes or behaviors through persuasion and social influence, ",
            tag: 'Workshop - Social Sciences',
            image: '/uploads/event-bg-ph.jpg',
            url: 'https://www.hbku.edu.qa/en/academic-events/CSE-SUST-ICPT22',
          },
          {
            day: "08",
            mounth: 'Apr',
            name: 'Parkinson’s Disease: Early Clinical Diagnosis, Red Flags and Treatment.',
            description: "Parkinson's disease (PD) is a neurodegenerative disease that usually affects older people. The disease affects a part of the brain that controls movement called the substantia nigra. The dead cells in this part no longer produce an important chemical called dopamine.",        
            tag: 'Parkinson / Webinar',
            image: '/uploads/event-bg-ph.jpg',
            url: 'https://www.hbku.edu.qa/en/academic-events/parkinson-disease-treatment',
          },
            
      ]
    }

    // const navigationIntern = [
    //   { text: 'Overview', hash: '#overview', isActive: true },
    //   { text: 'Facts', hash: '#facts-and-figures' },
    //   { text: 'Highlights', hash: '#latest-news' },
    //   { text: 'Upcoming ', hash: '#upcoming-research-activities' },
    // ]
    const navigationIntern = []

    return (
      <>
        <Navigation
          {...headerData}
          navigationIntern={navigationIntern}
          updateLang={this.props.updateLang}
        />
        {/* <section id='overview'>
          <div className='container'>
            <img className='main-image' src='/uploads/main-image.png' alt='main qsd' />
            <div
              className=' text-center display1'
              dangerouslySetInnerHTML={{ __html: this.state.body }}></div>
          </div>
        </section> */}
        <BannerHero {...BannerData}  />
        {/* <ResearchDayBanner /> */}
        <section className="bg-blue text-white m-wrap">
          <div className='container'>
            <h2 className='text-white'>Facts and Figures <br /> 
            <span className={'h4'} style={{paddingTop:'5px'}}>Academic Year 2021/2022</span></h2>
            <div className='welcome-achevements'>
              {[
                { name: 'Number of Invention Disclosures in AY21-22 ', value: '87', icon:"/uploads/icons/nd-w.svg" },
                { name: 'Number of Patents AY21-22 ', value: '14', icon:"/uploads/icons/np-w.svg" },
                { name: 'Avg Institutional H-index ', value: '19.178', icon:"/uploads/icons/hindex-w.svg" },
                { name: 'Number of students/faculty start-ups incubated AY21-22 ', value: '01', icon:"/uploads/icons/nsfs-w.svg" },
              ].map((e, k) => (
                <div className='welcome-achevements-item' key={k}>
                  <div className="welcome-achevements-item-top">
                    <div className='welcome-achevements-item-icon'><img className='ff-icon' src={e.icon} alt='Facts and Figures icon' style={{width:'60px'}} /></div>
                    <div className='welcome-achevements-item-value'>{e.value}</div>
                  </div>
                  <div className='welcome-achevements-item-name'>{e.name}</div>
                </div>
              ))}
            </div>
            <div className='text-center'>
              <a href='/research/impact' className='action-white'>
                View More
              </a>
            </div>
          </div>
        </section>

        <GridCarousel
          itemID='latest-news'
          {...grid}
          items={this.state.items.slice(0,5)}
          subtitle=''
          title='Latest News'
          viewMore="/research/news"
          description='Latest HBKU Research News'
        />
           
        <FactsCaroussel
          {...factCarousel}
          eventItems={this.state.eventItems}
          parentId={this.state.id}
          subtitle=''
          itemId='upcoming-research-activities'
        />
        <NewsBanner {...HeaderBanner} />
        <MoreBannerLink url={this.state.quickLinks} />
        <Footer {...footerEn} />
      </>
    )
  }
}

export default HomePage
