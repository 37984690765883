// import $ from 'jquery';
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'

// import logo from '../../assets/image/HBKU-RGB-2021.png'
// import BreadCrumb from '../BreadCrumb'

// import '../../assets/scss/Navigation.scss'

// // function ChangeLang({ show, defaultlang, updateLang }) {
// //   const [lang, setLang] = useState(defaultlang ? defaultlang : 'ar')
// //   const languages = [
// //     { code: 'ar', text: 'العربية' },
// //     { code: 'en', text: 'english' },
// //   ]
// //   if (!updateLang) updateLang = () => setLang(languages.filter(e => e.code !== lang)[0].code)
// //   return (
// //     <a href={'https://www.hbku.edu.qa/ar'} target={'_blank'} rel="noreferrer" className='nav-link nav-language'>
// //                     {languages.filter(e => e.code !== lang)[0].text}
// //                   </a>
// //     // <span onClick={updateLang} className='nav-link nav-language'>
// //     //   {languages.filter(e => e.code !== lang)[0].text}
// //     // </span>
// //   )
// // }

// function Navigation(props) {
//   const { lang } = props
//   const [sidebar, setSidebar] = useState(false)
//   const  applicationLinks = [{ text: 'Apply now', class: 'bordred', link: 'https://hbkuqa.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply' }]

//   useEffect(() => {
//     $(".top-header a").on('click', function(event) {
//       var hash = this.hash;
//       if ($(hash).length > 0 ) {
//           event.preventDefault();
//          $('html, body').animate({
//           scrollTop: ($(hash).offset().top - 80 )
//         }, 200);
//       }
//     });
//   }, []);

//   return (
//     <>
//       <header dir={lang === 'ar' ? 'rtl' : 'ltr'}>
//         <div className='top-header'>
//           <div className='container'>
//             <a href="https://www.hbku.edu.qa/" target={'_blank'}>
//               <img className='logo' src={logo} alt='HBKU logo' />
//             </a>
//             <div className='navigation-top'>
//               <div className='navigation-top-application'>
//                 {applicationLinks.map((e, k) => (
//                   <a href={e.link} target={'_blank'} rel="noreferrer" key={k} className={e.class}>
//                     {e.text}
//                   </a>
//                 ))}
//                 {/* <ChangeLang updateLang={props.updateLang} defaultlang={lang ? lang : 'en'} /> */}
//               </div>
//               <div className='navigation-top-nav'>
//                 <button onClick={() => setSidebar(true)} className='btn d-inline-block d-md-none'>
//                   <i className='fas fa-bars'></i>
//                 </button>
//                 <div className={`d-md-none right-sidebar ${!sidebar ? 'closed' : ''}`}>
//                   <button className='btn-close' onClick={() => setSidebar(false)}>
//                     <i className='fas fa-times'></i>
//                   </button>
//                   <NavLink to='https://www.hbku.edu.qa/' exact>
//                     <img src='/uploads/home.png' alt='home page' className="d-sm-none d-xs-none" />
//                     <span className="d-md-none ">Home </span>
//                   </NavLink>
//                   {props.navigationTop.map((e, k) => (
//                     <NavLink key={k} to={e.link}>
//                       {e.text}
//                     </NavLink>
//                   ))}
//                 </div>

//                 <div className='d-none d-md-flex'>
//                   <a href="https://www.hbku.edu.qa/" target={'_blank'} rel="noreferrer">
//                     <img src='/uploads/home.png' alt='home page' />
//                   </a>
//                   {props.navigationTop.map((e, k) => (
//                     <React.Fragment key={k}>
//                     {/^https?:\/\//.test(e.link)
//           ? <a href={e.link} target={'_blank'} rel="noreferrer">{e.text}</a>
//           : <NavLink key={k} to={e.link}>{e.text}</NavLink>
//           } 
//                     </React.Fragment>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='header-hero'>
//           <div className='container'>
//             <BreadCrumb crumb={props.crumb} />
//             <h1>{props.title}</h1>
//           </div>
//         </div>

//         {props.navigationButtom &&      
//         <div className='header-category'>
//             <div className='container'>
//               <NavLink to='/research' exact>
//                 <img src='/uploads/home.png' alt='home page' />
//               </NavLink>
//               {props.navigationButtom.map((e, k) => {
//                 if(e.redirect){
//                   return <a rel="noreferrer" target='_blank' href={e.link}>{e.text}</a>
//                 }
//               return <NavLink key={k} to={e.link}>
//                 {e.text}
//                 </NavLink>
//               }
//               )}
//             </div>
//           </div>
//           }

//         {props.navigationCoreLabs &&      
//         <div className='header-category'>
//             <div className='container'>
//               {props?.navigationCoreLabs?.map((e, k) => {
//                 if(e.redirect){
//                   return <a rel="noreferrer" target='_blank' href={e.link}>{e.text}</a>
//                 }
//               return  <NavLink key={k} to={e.link}>
//                 {e.text}
//                 </NavLink>
//               }
//               )}
//             </div>
//           </div>
//           }
//       </header>
// { (props?.navigationIntern?.length > 0) ? (
//  <div className='header-intern'>
//  <div className='container'>
//    {props.navigationIntern?.map((menuItem, k) => {
//      let activeClass = '';
//      if(menuItem.isActive){
//        activeClass = 'active';
//      }
//      return (
//       <React.Fragment key={k}>
//        <a href={menuItem.hash} className={activeClass} >
//          {menuItem.text} <i className={menuItem.icon}></i>
//        </a>
//        </React.Fragment>
//      )
//    })}
//  </div>
// </div>
// ): ('')
// }
      
//     </>
//   )
// }

// export default Navigation


import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logoWhite from '../../assets/image/logo-white.svg'; 
import logoColor from '../../assets/image/logo-color.svg'; 
import applyNow from '../../assets/image/apply-now.gif'; 
import applyBtn from '../../assets/image/ic-applynow.svg'; 
// import homeBlack from '../../assets/image/home-black.svg'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import BreadCrumb from '../BreadCrumb';
import '../../assets/scss/Navigation.scss'

function Navigation(props) {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  const handleScroll = () => {
    const scrollThreshold = 100; 
    setIsScrolled(window.scrollY > scrollThreshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === '/';

  return (
    <>
      <header className={`header-main ${isHomePage ? 'home-header' : 'home-header white-always  header-inner'} ${isScrolled ? 'white-header':''}`}>
        <div className="container-lg px-0 border-bottom">
          <div className="row gx-0">
            <div className="col-auto header-logo-holder border-end d-flex align-items-center">
              <h1 className="mb-0">
                <a href="https://www.hbku.edu.qa/en" className="header-logo">
                  <img src={logoWhite} alt="HBKU" />
                  <img src={logoColor} className="logo-forwhite" alt="HBKU" />
                </a>
              </h1>
            </div>

            <div className="col">
              <div className="header-top justify-content-end border-bottom d-none d-xl-flex">
                <nav className="header-top-nav">
                  <ul role="menu" className="nav">
                  <li><a href="https://www.hbku.edu.qa/news" target="_blank">News</a></li>
                    <li><a href="https://www.hbku.edu.qa/en/about-hamad-bin-khalifa-university" target="_blank">About Us</a></li>
                    <li><a href="https://www.hbku.edu.qa/en/academics" target="_blank">Academics</a></li>
                    <li><a href="https://innovation.hbku.edu.qa/" target="_blank">Innovation</a></li>
                    <li><a href="https://www.hbku.edu.qa/en/life-hbku" target="_blank">Life at HBKU</a></li>
                    <li><a href="https://www.hbku.edu.qa/en/cis/contact-us" target="_blank">Contact Us</a></li>
                  </ul>
                </nav>
              </div>

              <div className="header-bottom d-flex align-items-start align-items-xl-center">
                <div className="header-bottom-start flex-grow-1">
                  <div className="navbar navbar-expand-xl">
                    {/* <button
                      className="navbar-toggler"
                      type="button"
                      onClick={toggleMenu}
                      aria-controls="headerMenu"
                      aria-expanded={menuExpanded}
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button> */}

                    <div className={`collapse navbar-collapse ${menuExpanded ? 'menu-header justify-content-xl-between show' : ''}`} id="headerMenu">
                      {
                        props.navigationCoreLabs ?
                        <ul className="navbar-nav">
                        {!isHomePage && 
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/" exact >Home</NavLink>
                        </li>
                        }
                        {
                          props?.navigationCoreLabs?.map((e,k)=>{
                            return (
                              <li className="nav-item" key={k}>
                                <NavLink className="nav-link" to={e?.link}>{e?.text}</NavLink>
                              </li>
                            )
                          })
                        }
                      </ul>
                      :
                        <ul className="navbar-nav">
                          {/* {!isHomePage && 
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/" exact >Home</NavLink>
                          </li>
                          } */}
                          {
                            props?.navigationButtom?.map((e,k)=>{
                              if(e.redirect){
                                return <li className="nav-item">
                                        <a rel="noreferrer" className="nav-link" target='_blank' href={e.link}>{e.text}</a>
                                      </li>
                              }
                              return (
                                <li className="nav-item" key={k}>
                                  <NavLink className="nav-link" to={e?.link}>{e?.text}</NavLink>
                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                       <nav className="navbar-nav-bottom d-block d-xl-none">
                        <ul role="menu">
                          <li><a href="https://www.hbku.edu.qa/en/about-hamad-bin-khalifa-university" target="_blank">About Us</a></li>
                          <li><a href="https://www.hbku.edu.qa/en/academics" target="_blank">Academics</a></li>
                          <li><a href="https://innovation.hbku.edu.qa/" target="_blank">Innovation</a></li>
                          <li><a href="https://www.hbku.edu.qa/en/life-hbku" target="_blank">Life at HBKU</a></li>
                        </ul>
                      </nav> 
                    </div>
                  </div>
                </div>

                <div className="header-bottom-end d-flex align-items-center gap-xxl-4 gap-3 border-start1">
                  {/* <a href="https://hbkuqa.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply" target="_blank" className="btn btn-apply"> */}
                    {/* Use Lottie or another animation library as needed */}
                    {/* <img src={applyNow} width="30" height="30" alt="apply-now" className="apply-now-gif me-2" />
                    <img src={applyBtn} width="30" height="30" className="img-fluid d-none apply-now-img me-2" alt="apply-now"/>  */}
                    {/* Apply now
                  </a> */}

                  {/* <div className="header-dropdown">
                    <a className={`btn btn-user dropdown-toggle1 ${isDropdownOpen ? 'show' : ''}`} href="#" role="button" data-bs-toggle="dropdown" 
                      onClick={(e) => {e.preventDefault(); setIsDropdownOpen(!isDropdownOpen); }} aria-expanded={isDropdownOpen}>
                      User
                    </a>
                    <ul className={`dropdown-menu ${isDropdownOpen ? 'show user-dropdown-btn' : ''}`}>
                      <li><a className="dropdown-item" href="#">Student Login</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                    </ul>
                  </div> */}

                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                    aria-controls="headerMenu"
                    aria-expanded={menuExpanded}
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </header>
      { !isHomePage &&
        <div className='header-hero'>
            <div className='container'>
              <BreadCrumb crumb={props.crumb} />
              <h1>{props.title}</h1>
            </div>
        </div>
        }
      { (props?.navigationIntern?.length > 0) ? (
        <div className='header-intern'>
          <div className='container'>
            {props.navigationIntern?.map((menuItem, k) => {
              let activeClass = '';
              if(menuItem.isActive){
                activeClass = 'active';
              }
              return (
                <React.Fragment key={k}>
                <a href={menuItem.hash} className={activeClass} >
                  {menuItem.text} <i className={menuItem.icon}></i>
                </a>
                </React.Fragment>
              )
            })}
          </div>
        </div>
        ): ('')
        }
    </>
  );
}

export default Navigation;