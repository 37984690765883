import '../../assets/scss/Compliance.scss'

const IRB = () => {
    return(
        <>
        <section className="m-wrap about-ovpr irb-ibc">
          <div className='container'>
            <h2 className="text-hbku-primary p-b-3">Institutional Review Board (IRB)</h2>
            <p>The Hamad Bin Khalifa University Institutional Review Board (HBKU-IRB) is responsible for the ethical protection of the rights and welfare of human subjects from research risks. The IRB assures that all human subjects research activities conducted under the auspices of HBKU are ethically and methodologically sound, as required under public law and as expected by the public. </p>
            <p>The IRB is a highly professional committee that reviews and approves human research protocols of various disciplines and all related processes and documents to ensure that the rights and welfare of human subjects are protected above all other considerations. The IRB has the authority to approve, require modifications or disapprove all research activities that fall within their jurisdiction in conformity with all ethical foundations for the protection of human subjects. The IRB functions independently to protect its ethical mission without compromise and without any perception of conflict of interest or undue influence of power.</p>
           
            <p>The Ministry of Public Health (MOPH) has provided assurance to HBKU's Institutional Review Board that it will adhere to Qatari laws on <a className='text-primary' href='https://research.moph.gov.qa/en/Pages/HumanResearch.aspx' target="_target">research involving human subjects.</a> </p>
            
            <p>The HBKU colleges and research institutes, as well as any other institution that depends on HBKU-IRB via a reliance agreement, are the only entities that are subject to the HBKU-IRB's obligation for approving research proposals involving human subjects. Any joint project including research activities at locations other than the aforementioned institutes must be approved by the IRB office of those locations. Prior to beginning any research activity outside of Qatar, the activity must first receive the necessary ethical permission from the foreign nation.</p>
            
            <p> <strong>Important Notice: Summer Review Delays (June-August)<br></br> The HBKU-IRB members will be traveling intermittently during the summer. As such, there will be delays in reviewing new submissions, with the review timeframe extended to approximately 5 weeks. Thank you for your understanding.</strong></p>
            
            <div className="l-article l-accordion l-accordion4">
                <div className="mez slider">
                    <h3 className="l-accordion__title">
                        <span>How to apply for IRB</span>
                    </h3>
                    <div className="l-accordion__content">
                        <p>For IRB applications, please refer to the “<a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_application_guidelines_and_faqs.pdf" target="_blank">IRB Application Process Guide and FAQs</a> ”.</p>
                        <p>If you already have an account, please click on this link to complete your submission : <a href="https://qbri.app.cayuse.com" target="_blank"> HBKU-IRB Cayuse</a> </p>
                        <p>To create a new account please email <a href="mailto:hbkuirb@hbku.edu.qa">hbkuirb@hbku.edu.qa </a></p>
                        <p>Click here to <a href="https://www.hbku.edu.qa/sites/default/files/end_user_irb_training-1-2-2.mp4" target="_blank">watch Cayuse system training video</a> </p>
                        <p>For renewal/or amendments (of applications approved before the date: 08 September 2021), please click on this link: <a href="https://hbku.wufoo.com/forms/qbrihbku-institutional-review-board/" target="_blank">Wufoo Submission</a></p>
                    
                        <p>If you have any questions, please send an email to <a href="mailto:hbkuirb@hbku.edu.qa">hbkuirb@hbku.edu.qa</a></p>
                    </div>
                    <h3 className="l-accordion__title">
                        <span>Guidelines and Templates</span>
                    </h3>
                    <div className="l-accordion__content">
                        <ul>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_application_guidelines_and_faqs.pdf" target="_blank">HBKU-IRB Application Process Guide and FAQs </a></li>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/citi_human_subjects_research_course_enrollment_guide.pdf" target="_blank">CITI Human Subjects Research Course Enrollment Guide</a></li>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_institutional_approval.docx" target="_blank">Institutional Approval Template</a></li>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_supervisor_approval.docx" target="_blank">Supervisor Approval Template</a></li>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_scientific_evaluation.docx" target="_blank">Scientific Evaluation</a></li>
                        </ul>
                    </div>
                    <h3 className="l-accordion__title">
                        <span>HBKU-IRB Submission Documents</span>
                    </h3>
                    <div className="l-accordion__content">
                        <ul>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/informed_consent_social.docx" target="_blank">Informed consent (Social) – Eng
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_informed_consent_social_studies_-_ar.docx" target="_blank">Informed consent (Social) – Ar 
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_informed_consent_biomedical_studies_-_eng.docx" target="_blank">Informed consent (Biomedical) – Eng
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_informed_consent_biomedical_studies_-_ar.docx" target="_blank">Informed consent (Biomedical) – Ar</a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/cover_memo_for_irb_submission_hbku-ovpr-gov-irb-form-cm-003-00.docx" target="_blank">Cover memo (New application, Amendments and Renewals via Wufoo)
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/annual_continutation_request_form_hbku-ovpr-gov-irb-form-acr-003-00.docx" target="_blank">Annual continuation request form (Renewals via Wufoo)
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/co-investigator_information_form_hbku-ovpr-gov-irb-form-ci-003-00.docx" target="_blank">Co-investigator form (for Wufoo applications)
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/conflict_of_interest_disclosure_form_for_hbku_employees_hbku-ovpr-gov-irb-form-coi-disc-hbkuemployees-003-00.docx" target="_blank">HBKU Conflict of Interest Disclosure Form
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/conflict_of_interest_disclosure_form_for_collaborators_hbku-ovpr-gov-irb-form-coi-disc-collaborators-003-00.docx" target="_blank">Collaborator Conflict of Interest Disclosure Form
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/adverse_events_form.docx" target="_blank">Adverse Events Form
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/delegation_of_responsibility.docx" target="_blank">Delegation of Responsibilities
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/serious_adverse_events_form.docx" target="_blank">Serious Adverse Events
                        </a></li>
                        </ul>
                    </div>
                </div>
            </div>


            <h3 className="text-hbku-primary p-b-2 p-t-2 m-t-3">HBKU-IRB Members</h3>
            <p>HBKU-IRB members include scientists, non-scientists, physicians, ethicists, and individuals with expertise in allied disciplines. The HBKU-IRB is structured as follows: </p>
            <div className="filter-container">
                <table className="views-table full-width">
                    <thead>
                        <tr>
                            <th className="f-project-title">Name </th>
                            <th className="f-project-lead">Entity </th>
                            <th className="f-project-entity">Role </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="odd">
                          <td>Dr. Dena Al-Thani</td>
                          <td>HBKU CSE</td>
                          <td>Chair</td>
                      </tr>
                    <tr className="even">
                          <td>Dr. Pablo Rodriguez del Pozo</td>
                          <td>Weill Cornell Medicine-Qatar</td>
                          <td>Co-Chair</td>
                      </tr>
                         <tr className="odd">
                            <td>Dr. Abdolmehdi Riazi</td>
                            <td>HBKU CHSS</td>
                            <td>Co-Chair</td>
                        </tr>
                         <tr className="even">
                            <td>Dr. Khalid Al Ali</td>
                            <td>Ministry of Education and Higher Education</td>
                            <td>Member</td>
                        </tr>
  
                      <tr className="odd">
                            <td>Dr. Tareq Al-Ansari</td>
                            <td>HBKU CSE</td>
                            <td>Member</td>
                        </tr>
                        <tr className="even">
                            <td>Dr. Fouad Al-Shaban</td>
                            <td>HBKU QBRI</td>
                            <td>Member</td>
                        </tr>
                        <tr className="odd">
                            <td>Dr. Muna Al-Tamimi</td>
                            <td>Hamad Medical Corporation</td>
                            <td>Member</td>
                        </tr>
                        <tr className="even">
                            <td>Dr. Mohamed Emara</td>
                            <td>Qatar University</td>
                            <td>Member</td>
                        </tr>
                        <tr className="odd">
                            <td>Dr. George Nemer</td>
                            <td>HBKU CHLS</td>
                            <td>Member</td>
                        </tr>
  
                      
                    </tbody>
                </table>
            </div>
            <br /> <br />
            <h3 className="text-hbku-primary p-b-2 p-t-2">IRB Meeting Dates 2024</h3>
            <p>IRB meetings typically take place on the last Tuesday of every month.</p>
           {/* <div className="filter-container">
                <table className="views-table full-width">
                    <thead>
                        <tr>
                            <th className="f-project-title">Month </th>
                            <th className="f-project-lead">Submission Deadline </th>
                            <th className="f-project-entity">Meeting Dates </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="even">
                        <td>April</td>
                        <td className="text-center">Ramadan and Eid*</td>
                        <td> &nbsp; </td>
                    </tr>
                    <tr className="odd">
                        <td>May</td>
                        <td>16 May</td>
                        <td>30 May</td>
                    </tr>
                    <tr className="even">
                        <td>June</td>
                        <td>13 June</td>
                        <td>27 June</td>
                    </tr>
                    <tr className="odd">
                        <td>July</td>
                        <td>11 July</td>
                        <td>25 July</td>
                    </tr>
                    <tr className="even">
                        <td>August</td>
                        <td>15 August</td>
                        <td>29 August</td>
                    </tr>
                    <tr className="odd">
                        <td>September</td>
                        <td>12 September</td>
                        <td>26 September</td>
                    </tr>
                    <tr className="even">
                        <td>October</td>
                        <td>17 October</td>
                        <td>31 October</td>
                    </tr>
                    <tr className="odd">
                        <td>November</td>
                        <td>14 November</td>
                        <td>28 November</td>
                    </tr>
                    <tr className="even">
                        <td>December</td>
                        <td>5 December</td>
                        <td>19 December</td>
                    </tr>
                    </tbody>
                </table>
                <br></br>
            </div> */}
            <p><strong>Kindy be advised: </strong> IRB submissions are reviewed on a first-come, first-served basis. Complete applications must be submitted by the “Submission Deadline” for consideration at the corresponding meeting. Assignment to an agenda is not automatic and may vary depending on various factors, including the quality and completeness of the submission, complexity of the project, requirement of additional reviews and responsiveness of the researcher to the requests from the IRB Coordinator. Meeting dates are tentative upon achieving quorum for each meeting scheduled.</p>
            
          </div>
        </section>
        </>
    )
}

export default IRB;